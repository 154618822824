<script setup>
import { useSettings } from "~/composables/useSettings";

const props = defineProps(["dialog"]);
const emit = defineEmits(["update:dialog"]);

const dialog = computed({
  get: () => props.dialog,
  set: (val) => emit("update:dialog", val),
});

const { setLocale, activeLocale } = useTranslation();

function selectLocale(locale) {
  const { settings } = useSettings();
  settings.language = locale;

  setLocale(locale);

  setTimeout(() => close(), 500);
}

function close() {
  dialog.value = false;
}
</script>
<template>
  <v-dialog v-model="dialog" left-sheet>
    <div class="the-main-menu">
      <div @click="navigateTo('/')" class="logo">
        <img src="/icons/logo.svg" alt="logo" class="mt-2" />
      </div>
      <ul>
        <li
          @click="
            useEvent('bible.open');
            close();
          "
          class="item"
        >
          {{ transl("Home") }}
        </li>
        <li
          @click="
            useEvent('navigation.opensearch');
            close();
          "
          class="item"
        >
          {{ transl("Search") }}
        </li>
        <li
          @click="
            useEvent('navigation.opengallery');
            close();
          "
          class="item"
        >
          {{ transl("Gallery") }}
        </li>
        <li
          @click="
            useEvent('navigation.openbibleplans');
            close();
          "
          class="item"
        >
          {{ transl("Plan") }}
        </li>

        <li
          @click="
            useEvent('courses.open');
            close();
          "
          class="item"
        >
          {{ transl("Courses") }}
        </li>

        <li
          @click="
            useEvent('history.open');
            close();
          "
          class="item"
        >
          {{ transl("History of reading") }}
        </li>
        <!-- <li
          @click="
            useEvent('registration.open');
            close();
          "
          class="item"
        >
          {{ transl("Registration") }}
        </li> -->
        <li
          @click="
            useEvent('settings.open');
            close();
          "
          class="item"
        >
          {{ transl("Settings") }}
        </li>
        <div class="lang mt-9 pr-7">
          <div @click="selectLocale('uk')" :class="{ active: activeLocale == 'uk' }">
            ua
          </div>
          <div @click="selectLocale('ru')" :class="{ active: activeLocale == 'ru' }">
            ru
          </div>
          <div @click="selectLocale('en')" :class="{ active: activeLocale == 'en' }">
            en
          </div>
        </div>
      </ul>
    </div>
  </v-dialog>
</template>
<style scoped>
.item {
  margin: 0 14px;
  font-size: 18px;

  border-radius: 7px;
  padding: 10px 18px;
  color: var(--root-dark);
}
/* .item:hover {
  background: var(--root-green);
  color: var(--root-white);
  box-shadow: 0 7px 12px 0 rgba(0, 191, 164, 0.25);
  cursor: pointer;
} */

@media (hover: hover) {
  .item:hover {
    background: var(--root-green);
    color: var(--root-white);
    box-shadow: 0 7px 12px 0 rgba(0, 191, 164, 0.25);
    cursor: pointer;
  }
}
@media (hover: none) {
  .item:active {
    background: var(--root-green);
    color: var(--root-white);
    box-shadow: 0 7px 12px 0 rgba(0, 191, 164, 0.25);
    cursor: pointer;
  }
}
.icon-size {
  background-color: var(--root-green);
  width: 32px;
  height: 32px;
  border-radius: 8px;
  margin-right: 15px;
}
.icon {
  width: 17px;
  height: 17px;
}

.the-main-menu {
  width: 300px;
  z-index: 101;
  position: relative;
  height: 100%;
  font-size: 40px;
  background: #fff;
  padding-top: 15px;
}
.the-main-menu ul {
  margin: 20px 0 0 0;
  padding: 0;
}
/* .the-main-menu ul li {
  display: flex;
  min-height: 50px;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  padding-left: 30px;
} */
/* .the-main-menu ul li:hover {
  background-color: rgb(236, 236, 236);
} */
.the-main-menu ul .lang {
  padding-left: 24px;
  display: flex;
  font-size: 18px;
}

.the-main-menu ul .lang > div {
  display: inline-flex;
  position: relative;
  padding: 0 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.the-main-menu ul .lang > div:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 70%;
  background-color: var(--root-dark);
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

.the-main-menu ul .lang > div:nth-child(1):before {
  width: 0;
}

.the-main-menu ul div {
  /* margin-right: 10px; */
  /* color: var(--root-green); */
}

.active {
  color: var(--root-green);
}

.icon {
  color: var(--root-green);
}

.logo {
  /* background-color:  color: var(--root-green);; */
  text-align: center;
  width: 120px;
  /* margin: 0 auto; */
  padding-left: 32px;
}
</style>
